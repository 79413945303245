import './App.css';
import TextInfo from './components/TextInfo';

function App() {
  return (
    <div className='background-container'>
      <TextInfo />
    </div>
  );
}

export default App;
