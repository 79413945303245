import React from 'react';
import './Textinfo.style.css';
import { IoMailOutline } from 'react-icons/io5';
import { IoLogoInstagram } from 'react-icons/io';

const TextInfo = () => {
  return (
    <>
      <div className='textWhiteFrame'>
        <h1> MARIA DOCE </h1>
        <div className='contactContainer'>
          <h2 style={{ color: 'rgb(30,30,30)' }}>Site under construction</h2>
          <p> contact at:</p>
          <span style={{ display: 'flex', zIndex: 1000, gap: '45px' }}>
            <a href='mailto:mariadoce.santiago@gmail.com'>
              <IoMailOutline size={50} color={'#3C3922'} />
            </a>
            <a href='https://www.instagram.com/mariadocec/'>
              <IoLogoInstagram size={50} color={'#3C3922'} />
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default TextInfo;
